<template>
  <div class="vx-row flex center">
    <div class="vx-col w-full mb-base">
      <vx-card :title="$t('contact.title')" :subtitle="$t('contact.subtitle')" class="mb-base items-center">
        <!-- 搜尋 -->
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="4" vs-xs="12">
            <el-input style="width:180px" clearable @clear="clearSearchDoc" :placeholder="$t('contact.search')" @keyup.enter.native="searchDoctorBtn" v-model="querydoctor"/>
            <vs-button class="ml-3 py-2"  radius icon="search" :disabled="querydoctor.length==0" @click="searchDoctorBtn"></vs-button>
          </vs-col>
        </vs-row>
        <vs-row class="mt-10 mb-base">
          <!-- 新增聯絡人 -->
          <vs-col vs-lg="4" vs-sm="4" vs-xs="12">
            <vs-row vs-type="flex" vs-justify="center" vs-align="center">
              <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                <p>{{$t('contact.addContact')}}</p>
              </vs-col>
              <div class="mt-2 p-4 ps2 w-full overflow-scroll border border-solid border-grey">
                <ul>
                  <li class="ml-2">
                    {{searchDoctorList.length}} {{$t('contact.totalRecord')}}
                  </li>
                  <li class="ml-2" v-if="searchDoctorList!=0">
                    <vs-checkbox :checked="item.active" class="mt-1" :disabled="item.active" :vs-value="item" v-for="(item, index) in searchDoctorList" :key="index" @click="addDoctor(item)">{{item.family_name}}{{item.name}} ({{"ID:"+item.did}})</vs-checkbox>
                  </li>
                  <li class="ml-2" v-else>{{$t('contact.noData')}}</li>
                </ul>
              </div>
            </vs-row>
          </vs-col>
          <!-- 存在聯絡人 -->
          <vs-col class="sm:mt-0 mt-6" vs-lg="4" vs-sm="4" vs-xs="12">
            <vs-row vs-type="flex" vs-justify="center" vs-align="center">
              <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                <p>{{$t('contact.existContact')}}</p>
              </vs-col>
              <div class="mt-2 p-4 ps2 w-full overflow-scroll border border-solid border-grey">
                <vs-list>
                  <vs-list-item :title="contacts.length + ' ' + $t('contact.totalRecord')"></vs-list-item>
                  <vs-list-item :title="item.name" v-for="(item, index) in contacts" :key="index">
                    <vs-button size="small" class="ml-1" type="flat" icon="clear" @click="openDeletePopup(item)"></vs-button>
                  </vs-list-item>
                  <vs-list-item v-show="contacts==''" :title="$t('contact.noData')"></vs-list-item>
                </vs-list>
              </div>
            </vs-row>
          </vs-col>
        </vs-row>
      </vx-card>
    </div>
    <!--確認刪除聯絡人視窗-->
    <vs-popup :active.sync="PopupRemind" :title="$t('contact.deleteContactAlert')">
      <vs-col vs-type="flex" vs-justify="center">
        <vs-button class="mr-2" @click="PopupRemind=false">{{$t('popup.cancel')}}</vs-button> 
        <vs-button @click="delDoctor(contactItem)">{{$t('popup.confirm')}}</vs-button>
      </vs-col>
    </vs-popup>
  </div>
</template>

<script>
  import { searchDoctor } from '@/api/user'
  import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
  export default {
    components: {
      PerfectScrollbar
    },
    data() {
      return {
        data: [],
        value: [],
        settings             : {
          maxScrollbarLength : 60,
          wheelSpeed         : 0.70,
        },
        querydoctor: '', //搜尋醫生字串
        searchDoctorList: [], //搜尋醫生列表
        selectedDoctor: [], //選擇加入醫生
        PopupRemind:false, //關閉刪除聯絡人視窗
        contactItem:'',//聯絡人資料
      }
    },
    created() {
      var _self = this
      _self.$store.dispatch('fetchContact')
    },
    computed: {
      contacts: {
        get() {
          return this.$store.getters.contactsall
        },
        set() {}
      },
      windowWidth() {
        return this.$store.state.windowWidth
      },
    },
    methods: {
      // 清空搜尋欄位
      clearSearchDoc() {
        this.searchDoctorList = []
      },
      // 刪除聯絡人
      delDoctor(item) {
        var _self = this
        _self.PopupRemind = false
        _.map(_self.searchDoctorList, function(a){
          if(a.mid == item.mid){
            a.active = false
          }
          return a;
        })
        _self.$store.dispatch('removeContact', item)
            .then(() => {
              _self.$store.dispatch('fetchContact')
            })
      },
      // 新增聯絡人
      addDoctor(item) {
        var _self = this
        _.map(_self.searchDoctorList, function(a){
          if(a.mid == item.mid){
            item.active = true
          }
          return item;
        })
        _self.$store.dispatch('appendContact', item)
            .then(() => {
              _self.$store.dispatch('fetchContact')
            })
      },
      // 搜尋醫護人員
      searchDoctorBtn() {
        var _self = this
        var payload = {
          query: _self.querydoctor
        }
        if(_self.querydoctor!=''){
          _self.$vs.loading()
          searchDoctor(payload).then(res => {
            if(res.data.status=='OK'){
              let searchalldoctor = res.data.data
              let filterContact = []
              let userContact = []
              _self.contacts.forEach(element => {
                userContact.push(element.mid)
              });
              searchalldoctor.forEach(element => {
                if(!userContact.includes(element.mid)){
                  element.active = false
                  filterContact.push(element)
                }
              });
              _self.searchDoctorList = filterContact
            }
            _self.$vs.loading.close()
          })
        }
      },
      //開啟刪除聯絡人視窗 
      openDeletePopup(item) {
        this.contactItem = item
        this.PopupRemind = true
      }
    },
  };
</script>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"/>
<style>
  .ps2 {
    width: 300px;
    height: 400px;
  }
  .vx-card__title h6{
    color:black !important;
  }
  .vs-list--title{
    font-weight: 400 !important;
  }
</style>